import type { MaybeRefOrGetter } from 'vue'

const deOrderbookLinks = {
  app: DOB_EXTERNAL_LINKS_MAP.APP.url,
  bazaar: DOB_EXTERNAL_LINKS_MAP.BAZAAR.url,
  explorer: DOB_EXTERNAL_LINKS_MAP.EXPLORER.url,
  home: DOB_EXTERNAL_LINKS_MAP.HOME.url,
  stats: DOB_EXTERNAL_LINKS_MAP.STATS.url,
} as const

export type LinkKeyType = MaybeRefOrGetter<keyof typeof deOrderbookLinks>

interface ExplorerLinkParams {
  hash: string
  type: 'tx' | 'address'
}

type DeOrderBookLinkParams = ExplorerLinkParams | unknown

/**
 * @description Hook for accessing external links related to DeOrderbook.
 * @export
 * @return {*}  {{
 *   links: typeof deOrderbookLinks
 *   openDeOrderbookLink: (key: LinkKeyType) => void
 * }}
 */
export function useDeOrderbookLinks(): {
  links: typeof deOrderbookLinks
  openDeOrderbookLink: (key: LinkKeyType) => void
  getDeOrderBookLink: (
    key: LinkKeyType,
    params?: DeOrderBookLinkParams,
  ) => string
}
export function useDeOrderbookLinks(linkKey: LinkKeyType): {
  links: typeof deOrderbookLinks
  openDeOrderbookLink: (key?: LinkKeyType) => void
  getDeOrderBookLink: (
    key?: LinkKeyType,
    params?: DeOrderBookLinkParams,
  ) => string
}
export function useDeOrderbookLinks(linkKey?: LinkKeyType) {
  /**
   * Opens the specified DeOrderbook link in a new window.
   *
   * @param key - The key of the link to open.
   */
  const openDeOrderbookLink = (key = linkKey) => {
    if (!key) {
      throw new Error('must pass key to openDeOrderbookLink')
    }

    window.open(deOrderbookLinks[toValue(key)], '_blank')
  }

  /**
   * Get the explorer link for the specified hash and type.
   * @param params - The hash and type of the explorer link.
   * @returns URL of the explorer link
   */
  const getExplorerLink = (params: ExplorerLinkParams) => {
    const { hash, type } = params
    return `${deOrderbookLinks.explorer}/${type}/${hash}`
  }

  /**
   * Get the DeOrderbook link for the specified app
   * TODO: Add more links as needed
   * @param key - The app key
   * @param params - Params for the link
   * @returns URL of the DeOrderbook link
   */
  const getDeOrderBookLink = (
    key = linkKey,
    params?: DeOrderBookLinkParams,
  ) => {
    if (!key) {
      throw new Error('Must pass key to getDeOrderBookLink')
    }
    switch (key) {
      case 'explorer':
        return getExplorerLink(params as ExplorerLinkParams)
      default:
        return deOrderbookLinks[toValue(key)]
    }
  }

  return {
    links: deOrderbookLinks,
    openDeOrderbookLink,
    getDeOrderBookLink,
  }
}
